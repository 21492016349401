.modal__outer__con {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  transition: 50ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  z-index: 99;

  @media (max-width: 640px) {
    .modal__inner__con {
      width: 95vw !important;
    }
  }

  .modal__inner__content {
    opacity: 1 !important;
    width: max-content;
    animation: fadein 0.1s forwards;
    overflow: visible;
    border-radius: 0.75rem;
    z-index: 100;

    /* width */
    ::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #ececee;
      border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #ececee;
    }
  }
}
